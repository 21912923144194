'use client'

import { useState, createContext, useEffect } from 'react'
import { IRate } from 'types/rates'

export interface IRateTTL extends IRate {
  expires: Date
  loaded: boolean
}

interface RatesContext {
  rate: IRateTTL | undefined
  error: boolean
  loading: boolean
}

const RatesContext = createContext<RatesContext>({
  rate: undefined,
  error: false,
  loading: false
})

const fetchRates = async (): Promise<IRate | undefined> => {
  const resp = await fetch(`/api/exchanger/rates`)
  const data = await resp.json()

  if (resp.status === 401) {
    window.location.replace('/api/auth/logout')

    return
  }

  if (resp.status !== 200) {
    throw data
  }

  return data
}

export function RatesProvider({ children }: { children: JSX.Element }) {
  const [rate, setRate] = useState<IRateTTL | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const value = { rate, loading, error }

  const initRates = async () => {
    try {
      setLoading(true)
      const current_rates = await fetchRates()

      if (current_rates === undefined) return
      const expires = new Date()

      expires.setDate(expires.getDate() + 1)
      expires.setUTCHours(2, 59, 59)

      const rates = { ...current_rates, expires: expires, loaded: true }

      localStorage.setItem('currency_rates', JSON.stringify(rates))

      setRate(rates)
    } catch (err) {
      setError(true)
    } finally {
      error && setError(false)
      setLoading(false)
    }
  }

  useEffect(() => {
    const init = async () => await initRates()
    const rates: IRateTTL = JSON.parse(localStorage.getItem('currency_rates') || 'null')

    if (!rates || new Date(rates.expires) <= new Date()) {
      init()
    } else {
      setRate(rates)
    }
  }, [])

  return <RatesContext.Provider value={value}>{children}</RatesContext.Provider>
}

export default RatesContext
