'use client'

import { useContext } from 'react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslations } from 'next-intl'
import NoticeContext from 'context/NoticeCtx'
import { ERROR_MESSAGES } from '@consts/messages'
import { ALERT_TYPES } from '@consts/alerts'
import ModalAlert from '@components/RiskCredit/Modal/Alert'
import WarningCircle from '@components/icons/WarningCircleIcon'
import CheckCircleIcon from '@components/icons/CheckCircleIcon'

const COLOR_BY_STATUS = {
  warning: {
    background: '#FFD84B',
    contrast: '#2C2930'
  },
  success: {
    background: '#4DEDBE',
    contrast: '#2C2930'
  }
}

const ICON_BY_STATUS = {
  warning: <WarningCircle height={24} width={24} />,
  success: <CheckCircleIcon height={24} width={24} />
}

export default function NoticeBadge() {
  const t = useTranslations()
  const { active, message, messageInfo, alertType, handleActive } = useContext(NoticeContext)
  const status = Object.values(ERROR_MESSAGES)?.includes(message) ? 'warning' : 'success'
  const isBadge = alertType === ALERT_TYPES.BADGE
  const isModal = alertType === ALERT_TYPES.MODAL

  const noticeBadgeVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  }

  setTimeout(() => {
    isBadge && handleActive(false)
  }, 4000)

  const shownMessage = messageInfo ? t(message, messageInfo) : t(message)

  return (
    <AnimatePresence>
      {active && isBadge && (
        <motion.div
          animate="visible"
          exit="hidden"
          initial="hidden"
          style={{
            position: 'fixed',
            bottom: '8px',
            zIndex: 9999,
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          variants={noticeBadgeVariants}
        >
          <Flex
            alignItems="center"
            bg={COLOR_BY_STATUS[status]?.background}
            borderRadius={{ base: 0, sm: 8 }}
            color={COLOR_BY_STATUS[status]?.contrast}
            gap={3}
            minH="72px"
            minW={{ base: '100%', sm: '400px' }}
            px={4}
            py={3}
          >
            <Box h={6} w={6}>
              {ICON_BY_STATUS[status]}
            </Box>
            <Text fontSize={'md'} lineHeight={'24px'} whiteSpace={'normal'}>
              {shownMessage}
            </Text>
          </Flex>
        </motion.div>
      )}
      {active && isModal && (
        <ModalAlert
          isOpen
          alertText={message}
          buttonCancelText="buttons.accept"
          withPrimaryButton={false}
          onClose={() => handleActive(false)}
        />
      )}
    </AnimatePresence>
  )
}
