'use client'

import React, { createContext, useContext, useState, useEffect } from 'react'
import { Crisp } from 'crisp-sdk-web'
import useUser from '@hooks/useUser'
import getPermissions from 'repositories/server/permissions'

type PermissionsContextType = {
  permissions: string[]
  loading: boolean
  error: boolean
  refetchPermissions: ({ correlationId }: { correlationId?: string }) => Promise<void>
}

const PermissionsContext = createContext<PermissionsContextType>({
  permissions: [],
  loading: true,
  error: false,
  refetchPermissions: async () => {}
})

export const usePermissionsContext = () => useContext(PermissionsContext)

export function PermissionsProvider({ children }: { children: React.ReactNode }) {
  const [permissions, setPermissions] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { email, nickname } = useUser()

  useEffect(() => {
    if (!email) return

    Crisp.user.setEmail(email)
    Crisp.user.setNickname(nickname || '')
  }, [email, nickname])

  const fetchPermissions = async ({ correlationId }: { correlationId?: string } = {}) => {
    setLoading(true)
    setError(false)
    try {
      const fetchedPermissions = await getPermissions({ correlationId })

      setPermissions(fetchedPermissions)
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log({ err })

      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const refetchPermissions = async ({ correlationId }: { correlationId?: string } = {}) => {
    await fetchPermissions({ correlationId })
  }

  return (
    <PermissionsContext.Provider value={{ permissions, loading, error, refetchPermissions }}>
      {children}
    </PermissionsContext.Provider>
  )
}
