import { extendTheme, theme, ThemeProviderProps } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { type TenantsType } from '@consts/setupApp/tenants'
import { type RegionsType } from 'types/regions'
import { mappTenant } from '@consts/tenants/front'

import { inputTheme } from './components/Input'
import Form from './components/Form'
import Tabs from './components/Tabs'
import Menu from './components/Menu'
import Tooltip from './components/Tooltip'
import Button from './components/Button'
import Text from './components/Text'
import Modal from './components/Modal'
import Table from './components/Table'
import Checkbox from './components/CheckBox'
import Card from './components/Card'
import Badge from './components/Badge'
import Accordion from './components/Accordion'
import Select from './components/Select'
import Tag from './components/Tag'
import Drawer from './components/Drawer'
import { TextArea } from './components/Textarea'
import { popoverTheme } from './components/Popover'
import getColors from './Colors'
import getBaseStyles from './config/baseStyles'
import getAppFont from './Fonts'

const DEFAULT = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false
  },
  fontSizes: {
    xxs: '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '24px',
    xxl: '32px',
    xxxl: '40px'
  },
  breakpoints: { ...theme.breakpoints, lg: '1024px', xl: '1400px' }
}

export default function getCustomTheme({
  tenant,
  region
}: {
  tenant: TenantsType
  region: RegionsType
}) {
  const colors = getColors(tenant)
  const baseStyles = getBaseStyles(region)
  const tenantMapped = mappTenant[tenant] || tenant
  const font = getAppFont(tenantMapped)

  return extendTheme({
    ...DEFAULT,
    styles: {
      global: (props: ThemeProviderProps) => ({
        'html, body, #root': {
          color: mode('font', 'font')(props),
          height: '100%',
          bg: mode('background.main', 'background.main')(props),
          fontFamily: font.style.fontFamily
        },
        '*::-webkit-scrollbar-horizontal': {
          display: 'none'
        },
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px'
        },
        '::-webkit-scrollbar-track': {
          background: '#e6e6e6',
          borderRadius: '10px'
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#c5c5c5',
          borderRadius: '10px'
        },
        svg: {
          display: 'inline-flex'
        }
      })
    },
    colors,
    components: {
      Accordion,
      Badge,
      Button: {
        ...Button,
        baseStyle: {
          ...baseStyles?.base,
          ...Button.baseStyle,
          ...baseStyles?.button
        },
        variants: {
          ...Button.variants,
          primary: (props: ThemeProviderProps) => ({
            ...Button.variants.primary(props),
            ...baseStyles?.button
          }),
          white: (props: ThemeProviderProps) => ({
            ...Button.variants.white(props),
            ...baseStyles?.button
          }),
          outline: (props: ThemeProviderProps) => ({
            ...Button.variants.outline(props),
            ...baseStyles?.button
          }),
          chip: (props: ThemeProviderProps) => ({
            ...Button.variants.chip(props),
            ...baseStyles?.button,
            borderColor: colors.primary[500],
            _selected: {
              bg: colors.primary[500],
              color: colors.primary.contrast
            }
          })
        }
      },
      Card: {
        ...Card,
        baseStyle: {
          ...Card.baseStyle,
          ...baseStyles?.card
        }
      },
      Checkbox: {
        ...Checkbox,
        baseStyle: {
          ...Checkbox.baseStyle,
          ...baseStyles?.checkbox
        },
        variants: {
          ...Checkbox.variants,
          primary: (props: ThemeProviderProps) => ({
            ...Checkbox.variants.primary(props),
            control: {
              ...Checkbox.variants.primary(props).control,
              _checked: {
                _hover: 'none',
                background: 'transparent',
                border: `1.5px solid ${colors.font}`
              }
            },
            icon: {
              color: colors.primary[500]
            }
          })
        }
      },
      Drawer,
      Form,
      Input: {
        ...inputTheme,
        baseStyle: {
          field: {
            ...baseStyles?.base,
            ...baseStyles?.input?.field
          }
        },
        variants: {
          pill: {
            ...inputTheme?.variants?.pill,
            field: {
              ...baseStyles?.base,
              ...baseStyles?.input?.field,
              ...inputTheme?.variants?.pill.field,
              _invalid: {
                border: `1px solid ${colors.error[500]}`,
                borderBottom: `1px solid ${colors.error[500]}`
              }
            }
          },
          unstyled: {
            ...inputTheme?.variants?.unstyled,
            field: {
              ...baseStyles?.base,
              ...baseStyles?.input?.field,
              ...inputTheme?.variants?.unstyled.field,
              _invalid: {
                borderBottom: `1px solid ${colors.error[500]}`,
                _hover: {
                  borderBottom: `1px solid ${colors.error[500]}`
                },
                _focus: {
                  borderBottom: `1px solid ${colors.error[500]}`
                }
              }
            }
          }
        }
      },
      Menu,
      Modal: {
        baseStyle: {
          ...Modal.baseStyle,
          dialog: {
            ...Modal.baseStyle.dialog,
            ...baseStyles?.base?.modal
          }
        }
      },
      Popover: popoverTheme,
      Select: {
        ...Select,
        baseStyle: {
          ...Select?.baseStyle,
          field: {
            ...baseStyles?.base,
            ...Select?.baseStyle?.field,
            ...baseStyles?.select?.field
          }
        }
      },
      Skeleton: {
        baseStyle: {
          ...baseStyles?.base
        },
        variants: {
          input: {
            ...baseStyles?.base,
            ...baseStyles?.input?.field
          },
          button: {
            ...baseStyles?.base,
            ...baseStyles?.button
          }
        }
      },
      Table,
      Tabs: {
        ...Tabs,
        variants: {
          ...Tabs.variants,
          outlined: {
            ...Tabs?.variants?.outlined,
            tablist: {
              ...Tabs?.variants?.outlined?.tablist,
              borderRadius: baseStyles?.base?.borderRadius
            },
            tab: {
              ...Tabs?.variants?.outlined?.tab,
              borderRadius: baseStyles?.base?.borderRadius
            }
          },
          'soft-rounded': {
            ...Tabs?.variants?.['soft-rounded'],
            tablist: {
              ...Tabs?.variants?.['soft-rounded']?.tablist,
              borderRadius: baseStyles?.base?.borderRadius
            },
            tab: {
              ...Tabs?.variants?.['soft-rounded']?.tab,
              borderRadius: baseStyles?.base?.borderRadius
            }
          },
          'soft-rounded-outlined': {
            ...Tabs?.variants?.['soft-rounded-outlined'],
            tablist: {
              ...Tabs?.variants?.['soft-rounded-outlined']?.tablist,
              borderRadius: baseStyles?.base?.borderRadius
            },
            tab: {
              ...Tabs?.variants?.['soft-rounded-outlined']?.tab,
              borderRadius: baseStyles?.base?.borderRadius
            }
          }
        }
      },
      Tag: {
        ...Tag,
        baseStyle: {
          ...Tag.baseStyle,
          container: {
            ...baseStyles?.base,
            ...baseStyles?.tag?.container
          }
        }
      },
      Text,
      Textarea: TextArea,
      Tooltip
    }
  })
}
