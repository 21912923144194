'use client'

import { useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { ReactElement, createContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  APPLICATION_TYPES,
  CLIENTS,
  DOCUMENT_PROCESSOR,
  DOCUMENT_PROCESSOR_CREATE,
  READINGS,
  SIMULATION,
  SIMULATOR,
  SUBMISSIONS
} from 'consts/applications'
import useUser from '@hooks/useUser'
import { Crisp } from 'crisp-sdk-web'
import { REPORTS } from '@consts/routes'
import { useParams, usePathname } from 'next/navigation'

type CTAComponent = { [key: string]: ReactElement }
interface AddElement {
  key: string
  element: React.ReactElement
}
interface ILayoutContext {
  btnOpenNavRef: React.MutableRefObject<HTMLButtonElement | null>
  CTAComponent: CTAComponent
  height: number
  isEmpty: boolean
  isNavOpen: boolean
  sectionType?: string
  pathnameIsDocumentProcessor: boolean
  pathnameIsOnboarding: boolean
  pathnameIsRisk: boolean
  pathnameIsClients: boolean
  pathnameIsSimulation: boolean
  pathnameIsDetail: boolean
  pathnameIsReadings: boolean
  isOpenNotifications: boolean
  addElement: ({ key, element }: AddElement) => void
  changeHeight: (height: number) => void
  onCloseNav: () => void
  onOpenNav: () => void
  removeElement: ({ key }: { key: string }) => void
  onToggleNotifications: () => void
  onCloseNotifications: () => void
}

const LayoutContext = createContext<ILayoutContext>({
  btnOpenNavRef: { current: null },
  CTAComponent: {},
  height: 0,
  isEmpty: true,
  isNavOpen: false,
  sectionType: undefined,
  pathnameIsReadings: false,
  pathnameIsDocumentProcessor: false,
  pathnameIsOnboarding: false,
  pathnameIsRisk: false,
  pathnameIsSimulation: false,
  pathnameIsClients: false,
  pathnameIsDetail: false,
  isOpenNotifications: false,
  addElement: () => {},
  changeHeight: () => {},
  onCloseNav: () => {},
  onOpenNav: () => {},
  removeElement: () => {},
  onToggleNotifications: () => {},
  onCloseNotifications: () => {}
})

export function LayoutProvider({ children }: { children: JSX.Element }) {
  const pathname = usePathname()
  const query = useParams()
  const [height, setHeight] = useState<number>(0)
  const [CTAComponent, setCTAComponent] = useState<CTAComponent>({})
  const { isOpen: isNavOpen, onOpen: onOpenNav, onClose: onCloseNav } = useDisclosure()
  const {
    isOpen: isOpenNotifications,
    onToggle: onToggleNotifications,
    onClose: onCloseNotifications
  } = useDisclosure()
  // const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
  const [isLargerThan1400] = useMediaQuery('(min-width: 1400px)')
  const [isLargerThan1000] = useMediaQuery('(min-width: 1024px)')
  const btnOpenNavRef = useRef<HTMLButtonElement>(null)
  const { email, nickname } = useUser()

  useEffect(() => {
    if (!email) return

    Crisp.user.setEmail(email)
    Crisp.user.setNickname(nickname || '')
  }, [email, nickname])

  const sectionType = useMemo(() => {
    if (pathname?.includes(APPLICATION_TYPES.ONBOARDING)) {
      return APPLICATION_TYPES.ONBOARDING
    }
    if (pathname?.includes(SUBMISSIONS)) {
      return SUBMISSIONS
    }
    if (pathname?.includes('application_group')) {
      return APPLICATION_TYPES.RISK_EVALUATION
    }
    if (pathname?.includes('create')) {
      return `${DOCUMENT_PROCESSOR}_create`
    }
    if (pathname?.includes(READINGS)) {
      return READINGS
    }
    if (pathname?.includes(DOCUMENT_PROCESSOR)) {
      return DOCUMENT_PROCESSOR
    }
    if (pathname?.includes(REPORTS)) {
      return REPORTS
    }
    if (pathname?.includes('/simulador')) {
      return SIMULATOR
    }
    if (pathname?.includes(CLIENTS)) {
      return CLIENTS
    }
    if (pathname?.includes(SIMULATION)) {
      return SIMULATION
    }
  }, [pathname])

  const pathnameIsDocumentProcessor =
    sectionType === DOCUMENT_PROCESSOR ||
    sectionType === DOCUMENT_PROCESSOR_CREATE ||
    sectionType === READINGS
  const pathnameIsOnboarding =
    sectionType === APPLICATION_TYPES.ONBOARDING || sectionType === SIMULATOR
  const pathnameIsRisk = sectionType === SUBMISSIONS
  const pathnameIsReadings = sectionType === READINGS
  const pathnameIsClients = sectionType === CLIENTS
  const pathnameIsSimulation = sectionType === SIMULATION
  const pathnameIsDetail =
    (pathnameIsRisk || pathnameIsOnboarding) && (!!query?.group_id || !!query?.application_id)

  const changeHeight = (height: number) => {
    setHeight(height)
  }

  const addElement = ({ key, element }: AddElement) => {
    setCTAComponent((prevElements) => ({
      ...prevElements,
      [key]: element
    }))
  }

  useEffect(() => {
    if (isLargerThan1400) {
      onOpenNav()
    } else {
      onCloseNav()
    }
  }, [isLargerThan1400])

  const removeElement = ({ key }: { key: string }) => {
    setCTAComponent((prevElements) => {
      const newElements = { ...prevElements }

      delete newElements[key]

      return newElements
    })
  }

  const isEmpty = !Object.keys(CTAComponent).length

  useEffect(() => {
    if (isLargerThan1000 && isNavOpen) {
      onCloseNav()
    }
  }, [isLargerThan1000])

  useEffect(() => {
    onCloseNotifications()
  }, [pathname])

  const value = {
    btnOpenNavRef,
    CTAComponent,
    height,
    isEmpty,
    isNavOpen,
    sectionType,
    pathnameIsDocumentProcessor,
    pathnameIsOnboarding,
    pathnameIsRisk,
    pathnameIsDetail,
    pathnameIsReadings,
    isOpenNotifications,
    pathnameIsClients,
    pathnameIsSimulation,
    onToggleNotifications,
    onCloseNotifications,
    addElement,
    changeHeight,
    onCloseNav,
    onOpenNav,
    removeElement
  }

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export default LayoutContext
