import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys
)

const baseStyle = definePartsStyle({
  table: {
    borderCollapse: 'collapse',
    whiteSpace: 'break-spaces'
  },
  tbody: {},
  thead: {
    th: {
      fontFamily: 'inherit',
      borderBottom: 'none',
      letterSpacing: '0.1em',
      textTransform: 'uppercase',
      fontSize: '10px',
      mixBlendMode: 'normal',
      color: '#2C2930'
    },
    tr: {
      _odd: {
        bg: 'transparent',
        cursor: 'default'
      }
    }
  },
  tfoot: {},
  caption: {},
  tr: {},
  th: {
    fontFamily: 'inherit',
    _first: {
      pl: { base: 8, xl: 10 }
    },
    _last: {
      pr: { base: 8, xl: 10 }
    }
  },
  td: {
    backgroundColor: 'rgb(239 239 239)',
    _first: {
      borderLeftRadius(theme) {
        return theme.radii.md
      },
      pl: { base: 8, xl: 10 }
    },
    _last: {
      borderRightRadius(theme) {
        return theme.radii.md
      },
      pr: { base: 8, xl: 10 }
    }
  },
  col: {},
  colgroup: {}
})

const fixed = defineStyle({
  table: {
    height: 'fit-content',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    fontSize: { base: 'xs', lg: 'sm' },
    'tr>th:first-of-type,tr>td:first-of-type': {
      position: 'sticky',
      left: 0
    },
    '*': {
      textWrap: 'nowrap'
    }
  },
  tbody: {
    td: {
      w: { base: '200px', xl: '250px' },
      lineHeight: '1rem',
      py: { base: 1, sm: 2 },
      px: { base: '7px', sm: '15px' },
      _first: {
        pl: { base: '10px', sm: '20px' },
        pr: { base: '7px', sm: '15px' }
      },
      _last: {
        pr: { base: '10px', sm: '20px' }
      }
    },
    tr: {
      h: '100%'
    }
  },
  thead: {
    th: {
      bg: '#f5f5f5',
      fontFamily: 'inherit',
      w: { base: '200px', xl: '250px' },
      px: { base: '7px', sm: '15px' },
      py: 0,
      pl: 0,
      pb: 1,
      _first: {
        pl: { base: '10px', sm: '20px' },
        pr: { base: '7px', sm: '15px' }
      },
      _last: {
        pr: { base: '10px', sm: '20px' }
      }
    }
  }
})

const fixedLeftRight = defineStyle({
  table: {
    height: 'fit-content',
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    fontSize: { base: 'xs', lg: 'sm' },
    'tr>th:first-of-type,tr>td:first-of-type': {
      position: 'sticky',
      left: 0
    },
    'tr>th:last-of-type,tr>td:last-of-type': {
      position: 'sticky',
      right: 0
    },
    '*': {
      textWrap: 'nowrap'
    }
  },
  tbody: {
    td: {
      w: { base: '200px', xl: '250px' },
      lineHeight: '1rem',
      py: { base: 1, sm: 2 },
      px: { base: '7px', sm: '15px' },
      _first: {
        pl: { base: '10px', sm: '20px' },
        pr: { base: '7px', sm: '15px' }
      },
      _last: {
        pr: { base: '10px', sm: '20px' }
      }
    },
    tr: {
      h: '100%'
    }
  },
  thead: {
    th: {
      bg: '#fafafa',
      fontFamily: 'inherit',
      w: { base: '200px', xl: '250px' },
      px: { base: '7px', sm: '15px' },
      py: 0,
      pl: 0,
      pb: 1,
      _first: {
        pl: { base: '10px', sm: '20px' },
        pr: { base: '7px', sm: '15px' }
      },
      _last: {
        pr: { base: '10px', sm: '20px' }
      }
    }
  }
})

const dense = defineStyle({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 6px'
  },
  td: {
    fontSize: 'sm',
    lineHeight: '1rem',
    py: 2,
    px: 1,
    _first: {
      pl: 3
    },
    _last: {
      pr: 3
    }
  },
  thead: {
    th: {
      fontFamily: 'inherit',
      p: 0,
      px: 1,
      _first: {
        pl: 3
      },
      _last: {
        pr: 3
      }
    }
  }
})

const light = defineStyle({
  table: {
    borderCollapse: 'separate'
  },

  td: {
    borderTop: '1px solid #eaeaea',
    fontSize: 'sm',
    lineHeight: '1rem',
    bg: 'transparent',
    py: 4,
    px: 1,
    _first: {
      pl: 0
    },
    _last: {
      pr: 3
    }
  },
  thead: {
    fontSize: '12px',
    fontWeight: 500,
    th: {
      fontFamily: 'inherit',
      opacity: 1,
      p: 0,
      pb: 4,
      fontSize: '12px',
      color: 'font',
      fontWeight: 500,
      px: 1,
      _first: {
        pl: 0
      },
      _last: {
        pr: 3
      }
    }
  },
  tbody: {}
})

const clickable = defineStyle({
  table: {
    borderCollapse: 'collapse'
  },
  tbody: {
    _odd: {
      bg: '#E8E8E8'
    }
  },
  thead: {
    th: {
      fontFamily: 'inherit',
      opacity: 1
    },
    tr: {
      _odd: {
        bg: '#fafafa',
        cursor: 'default'
      }
    }
  },
  tfoot: {},
  caption: {},
  th: {
    fontFamily: 'inherit'
  },
  tr: {
    _even: {
      bg: '#fafafa'
    },
    _odd: {
      bg: 'rgba(44, 41, 48, 0.03)'
    },
    _hover: {
      cursor: 'pointer',
      bg: '#E8E8E8'
    }
  },
  td: {
    w: 'auto',
    bg: 'transparent',
    _first: {
      borderLeftRadius: 0
    },
    _last: {
      borderRightRadius: 0
    }
  },
  col: {},
  colgroup: {}
})

const Table = defineMultiStyleConfig({
  baseStyle,
  variants: { clickable, dense, light, fixed, 'fixed-left-right': fixedLeftRight }
})

export default Table
