const Tabs = {
  variants: {
    outlined: {
      tablist: {
        height: '40px',
        color: 'font',
        border: 'solid 1px #CACACA'
      },
      tab: {
        border: '2px solid transparent',
        px: '6',
        _selected: { color: 'font', border: '2px solid ' }
      }
    },
    'soft-rounded': {
      root: {
        color: 'font',
        margin: 'auto'
      },
      tablist: {
        border: '1px solid #DADADA',
        gap: { base: 2, lg: 6 }
      },
      tab: {
        fontWeight: 'bold',
        fontSize: '12px',
        _selected: {
          color: 'font',
          bg: 'transparent',
          border: 'solid 1.5px',
          borderColor: 'primary.500'
        }
      }
    },
    'soft-rounded-outlined': {
      tablist: {
        gap: '8px'
      },
      tab: {
        border: 'solid 2px',
        borderColor: 'font',
        fontWeight: 'bold',
        fontSize: '12px',
        color: 'font',
        opacity: 0.3,
        _selected: {
          opacity: 1
        }
      }
    },
    solid: {
      root: {
        background: 'rgba(44, 41, 48, 0.05)',
        borderRadius: 5,
        color: 'font',
        padding: '4px 5px'
      },
      tablist: {
        gap: '7px'
      },
      tab: {
        padding: '6px 9px',
        fontSize: '12px',
        lineHeight: '12px',
        _selected: {
          background: 'primary.500',
          color: 'primary.contrast',
          borderRadius: 5,
          fontWeight: 500
        }
      }
    },
    line: {
      tab: {
        color: 'font',
        fontSize: 'sm',
        fontWeight: 700,
        _selected: {
          color: 'primary.500'
        },
        _disabled: {
          color: '#AAA'
        }
      }
    }
  }
}

export default Tabs
